<template>
  <div class="common-page">
    <header v-if="isShowHeader" class="page-header">
      <div @click="goBackForCheck" class="header-left">
        <Icon class="back-icon" type="md-arrow-round-back" />
        <span class="back-title">返回{{params.backTitle}}</span>
      </div>
      <div class="header-title">编辑申诉单</div>
    </header>
    <Spin size="large" fix v-if="searchLoading"></Spin>
    <section v-if="complaintData" class="page-content">
      <div class="govern-content">
        <div class="govern-notice">
          <div class="notice-item">
            <span class="notice-label">申诉车辆:</span>
            <span class="notice-value">{{`${complaintData.vehicleNo}(${complaintData.vehiclePlateColor})`}}</span>
          </div>
          <div class="notice-item">
            <span class="notice-label">所属企业:</span>
            <span class="notice-value">{{ complaintData.unitName }}</span>
          </div>
          <div class="notice-item">
            <span class="notice-label">申诉提交时间:</span>
            <span class="notice-value">{{complaintData.appealTime}}</span>
          </div>
          <div class="notice-item">
            <span class="notice-label">申诉单:</span>
            <span class="notice-value">{{ complaintData.appealId }}</span>
          </div>
          <div class="notice-item flex-item" style="display:flex">
            <span class="notice-label">添加申诉附件:</span>
            <Upload
              ref="upload"
              :before-upload="beforeFileUpload"
              :on-success="uploadSuccess"
              :on-error="uploadError"
              :on-remove="removeFile"
              :default-file-list="defalutFileList"
              :action="uploadUrl"
              :headers="uploadHeader"
              :on-exceeded-size="handleMaxSize"
              :max-size="10000"
              multiple
            >
              <span class="btn-span">选择文件</span>
            </Upload>
          </div>
        </div>
        <header class="page-header">
          <div class="header-left">
            <div class="title-instruction"></div>
            <span class="header-title">申诉内容</span>
          </div>
          <div class="header-right"></div>
        </header>
        <div class="detail-item" v-for="item in alarmData" :key="item.alarmType">
          <header class="item-title">违章类型: {{ item.alarmTypeRe }}</header>
          <div class="item-table">
            <Table
              @on-select-all="selectType(item.alarmType)"
              @on-select-all-cancel="selectType(item.alarmType)"
              @on-select="selectType(item.alarmType)"
              @on-select-cancel="selectType(item.alarmType)"
              @on-selection-change="selectChange"
              :columns="alarmTableColmuns.find(aItem => aItem.id == item.alarmType).colmuns"
              :data="item.alarmDataList"
              :ref="'selection' + item.alarmType"
              size="small"
              max-height="300"
            >
            </Table>
            <div class="complaintDescribe">
              <span class="header-title describe-title">申诉描述: </span>
              <Input v-model="item.appealDesc" type="textarea" placeholder="请输入申诉描述" />
            </div>
          </div>
        </div>
        <div class="detail-item">
          <div class="btn-wrapper">
            <Button class="option-btn" type="primary" v-if="isShowPrevStepBtn" @click="goBackCreate" icon="ios-undo">上一步</Button>
            <Button v-if="btnPrivilegeCode.complaint.includes('update') && fileUploadFinish" class="option-btn" type="primary" :loading="storage_loading" @click="commitInfo('storage')">暂存</Button>
            <Button v-if="btnPrivilegeCode.complaint.includes('update') && fileUploadFinish" class="option-btn" type="primary" :loading="commit_loading" @click="commitInfo('commit')">提交</Button>
            <Button v-if="btnPrivilegeCode.complaint.includes('Revoke')" class="option-btn" type="warning" @click="repeal">撤销</Button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getToken } from '@/assets/js/sessionStorage.js'
import { btnPrivilegeMixin, paginationMixin, alarmTableMixin, privilegeMixin, userInfoMixin } from '@/assets/js/mixin.js'
import axios from 'axios'
export default {
  mixins: [btnPrivilegeMixin, paginationMixin, alarmTableMixin, privilegeMixin, userInfoMixin],
  props: {
    params: {
      type: Object,
      default: () => {
        return {
          targetRow: null,
          backTitle: '',
          backComponentName: ''
        }
      }
    },
    isShowPrevStepBtn: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    isShowHeader: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  data() {
    return {
      complaintData: null,
      showFileList: [],
      localSelectType: 0,
      alarmData: [],
      complaintDescribe: '',
      searchLoading: false,
      storage_loading:false,
      commit_loading:false,
      fileUploadFinish: true,
    }
  },
  computed: {
    uploadUrl() {
      return `${WEB_CONFIG.BASE_IP}/govern-web/file/uploadToObs`
    },
    uploadHeader() {
      return {
        Authentication: getToken(),
      }
    },
    defalutFileList() {
      return this.complaintData.fileList.map(item => {
        return {
          name: item.fileName,
          url: item.url
        }
      })
    }
  },
  created () {
    this.checkAppeal()
  },
  methods: {
    // 申诉
    checkAppeal () {
      if (this.params.targetRow && this.params.targetRow.appealId) {
        // 单号存在
        this.getAppeal(this.params.targetRow)
      } else {
        // 不存在则创建
        this.createAppeal(this.params.targetRow)
      }
    },
    // 创建申诉单
    async createAppeal (row) {
      let _params = {
        appKey: this.userInfo.appKey,
        unitId: this.userInfo.unitId,
        beginTime: row.beginTime,
        endTime: row.endTime,
        vehicleNo: row.vehicleNo ? row.vehicleNo : row.vehicle.split('-')[0],
        vehiclePlateColor: row.vehiclePlateColor ? row.vehiclePlateColor : row.vehicle.split('-')[1]
      }
      this.searchLoading = true
      try {
        let {data} = await axios({
          method: 'post',
          url: `${WEB_CONFIG.BASE_IP}/govern-web/appeal/createAppeal`,
          data: _params
        })
        this.searchLoading = false
        if (data.code === 200) {
          this.complaintData = data.data
          this.initData()
        } else {
          this.$Message.warning(data.msg)
        }
      } catch (error) {
        this.searchLoading = false
        if (!this.checkPrivilege(error)) {
          this.$Message.error('创建申诉单失败, 请稍后重试!')
        }
      }
    },
    // 获取申诉单信息
    async getAppeal (row) {
      let _params = {
        appKey: this.userInfo.appKey,
        appealId: row.appealId,
        beginTime: row.beginTime,
        endTime: row.endTime,
        unitId: this.userInfo.unitId,
        vehicleNo: row.vehicleNo,
        vehiclePlateColor: row.vehiclePlateColor
      }
      this.searchLoading = true
      try {
        let {data} = await axios({
          method: 'post',
          url: `${WEB_CONFIG.BASE_IP}/govern-web/appeal/loadBreakRuleData`,
          data: _params
        })
        this.searchLoading = false
        if (data.code === 200) {
          this.complaintData = data.data
          this.initData()
        } else {
          this.$Message.warning(data.msg)
        }
      } catch (error) {
        this.searchLoading = false
        if (!this.checkPrivilege(error)) {
          this.$Message.error('获取申诉单失败, 请稍后重试!')
        }
      }
    },
    // 初始化数据
    initData () {
      this.alarmData = this.complaintData.breakRuleDataList
      this.alarmTableColmuns.forEach(item => {
        this.alarmData.forEach(aItem => {
          if (aItem.alarmType === item.id) {
            aItem.alarmIdList = aItem.alarmDataList
            item.data = aItem.alarmDataList
            item.alarmType = aItem.alarmType
            this.$set(item, 'appealDesc', aItem.appealDesc)
            // item.appealDesc = aItem.appealDesc
            item.alarmTypeRe = aItem.alarmTypeRe
            let newList = item.data
            newList.forEach(one => {
              if (one.governType != 0) {
                if (one.governNo == this.complaintData.appealId) {
                  this.$set(one, '_checked', true)
                } else {
                  one._disabled = true
                }
              }
            })
          }
        })
        let column = item.colmuns
        let section = { type: 'selection', width: 80, align: 'center' }
        column.unshift(section)
        let endOne = {
          title: '是否已提交申诉/治理',
          key: 'speedingRate',
          align: 'center',
          render: (h, params) => {
            if (params.row.governType === 0) {
              return h('span', [h('span', {}, '否')])
            } else if (params.row.governType === 1) {
              return h('span', [h('span', {}, '申诉单' + params.row.governNo)])
            } else if (params.row.governType === 2) {
              return h('span', [h('span', {}, '治理单' + params.row.governNo)])
            } else if (params.row.governType === 3) {
              return h('span', [h('span', {}, '通报单' + params.row.governNo)])
            }
          },
        }
        column.push(endOne)
      })
      this.alarmData.forEach(one => {
        let newArr = []
        one.alarmIdList.forEach(itm => {
          if (itm._checked) {
            newArr.push(itm.alarmId)
          }
        })
        one.alarmIdList = newArr
      })
    },
    // 撤销
    repeal() {
      this.$Modal.confirm({
        title: '撤销',
        content: '撤销操作会删除申诉单内容,是否继续？',
        onOk: async () => {
      this.searchLoading = true
      let params = {
        appKey: this.userInfo.appKey,
        appealId: this.complaintData.appealId,
      }
      axios({
        method: 'post',
        url: `${WEB_CONFIG.BASE_IP}/govern-web/appeal/deleteAppeal`,
        data: params,
      })
        .then(res => {
          this.searchLoading = false
          if (res.data.code === 200) {
            this.$Message.success('已撤销')
            this.goBack()
          } else {
            this.$Message.warning(res.data.msg)
          }
        })
        .catch(error => {
          this.searchLoading = false
          if (!this.checkPrivilege(error)) {
            this.$Message.warning('撤销失败，请稍后再试')
          }
        })
        },
        onCancel: () => {}
      })
    },
    // 提交
    commitInfo (type) {
      // 文件列表
      let _fileList = this.$refs.upload.fileList.map(item => {
        let result = null
        if (item.response) {
          // 本次上传
          result = {
            fileName: item.response.data.fileName,
            url: item.response.data.fileURL,
          }
        } else {
          // 缓存请求的数据
          result = {
            fileName: item.name,
            url: item.url,
          } 
        }
        return result
      })
      let params = {
        alarmDataList: this.alarmData,
        appKey: this.userInfo.appKey,
        unitId: this.userInfo.unitId,
        beginTime: this.params.targetRow.beginTime,
        endTime: this.params.targetRow.endTime,
        vehicleNo: this.complaintData.vehicleNo,
        vehiclePlateColor: this.complaintData.vehiclePlateColor,
        appealId: this.complaintData.appealId,
        filePathList: _fileList,
      }
      if (type === 'commit') {
        this.commit_loading = true
        params.status = 2
      } else if (type === 'storage') {
        this.storage_loading = true
        params.status = 1
      }
      axios({
        method: 'post',
        url: `${WEB_CONFIG.BASE_IP}/govern-web/appeal/updateAppeal`,
        data: params,
      })
        .then(res => {
          this.searchLoading = false
          if (res.data.code === 200) {
            this.$Message.success('操作成功！')
            this.goBack()
          } else {
            this.$Message.warning(res.data.msg)
          }
          this.commit_loading = false
          this.storage_loading = false
        })
        .catch(error => {
          this.searchLoading = false
          this.commit_loading = false
          this.storage_loading = false
          if (!this.checkPrivilege(error)) {
            this.$Message.error('操作失败请稍后再试!')
          }
        })
    },
    // 选中表格中数据的方法
    selectChange(selection) {
      this.alarmData.forEach(item => {
        if (item.alarmType == this.localSelectType) {
          if (selection.length > 0) {
            let newArr = []
            selection.forEach(aItem => {
              newArr.push(aItem.alarmId)
            })
            item.alarmIdList = newArr
          } else {
            item.alarmIdList = []
          }
        }
      })
    },
    selectType(type) {
      this.localSelectType = type
    },

    // 处理文件上传方法
    beforeFileUpload() {
      this.fileUploadFinish = false
    },
    uploadSuccess(response, file, fileList) {
      this.fileUploadFinish = true
      if (response.code === 200) {
        this.showFileList = fileList
      } else {
        this.$Message.error('文件上传失败，请稍后再试')
        let index = this.$refs.upload.fileList.indexOf(file)
        this.$refs.upload.fileList.splice(index, 1)
      }
    },
    uploadError() {
      this.fileUploadFinish = true
      this.$Message.error('文件上传失败，请稍后再试')
    },
    handleMaxSize(file) {
      this.$Message.warning({
        content: '文件  ' + file.name + ' 过大, 不能超过 10M！',
        duration: 4,
      })
    },
    removeFile(file, fileList) {
      this.showFileList = fileList
    },
    goBackForCheck () {
      this.$Modal.confirm({
        title: '返回',
        content: '申诉单尚未建立成功,是否确认离开？',
        onOk: async () => {
          this.goBack()
        },
        onCancel: () => {}
      })
    },
    goBack () {
      this.$emit('goBack', this.params.backComponentName)
    },
    // 返回创建上一步
    goBackCreate() {
      this.$emit('goLastStep')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/common.scss';
@include common-detail-page();
.common-page {
  .btn-span {
    margin: 0 20px;
  }
}
.complaintDescribe {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding: 5px;
  .describe-title {
    width: 80px;
    font-family: '微软雅黑';
    font-weight: 409;
    font-style: normal;
    color: #999999;
    line-height: 28px;
  }
}
</style>
